import {
  Box,
  Container,
  HStack,
  Icon,
  Image,
  Link,
  SimpleGrid,
  Square,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IconCircleCheck, IconMail, IconPhone } from '@tabler/icons';
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react'

const IndexLastSection = () => {

    return (
    <Box as="section" bg="yellow.900">
        <Container py={{ base: '16', md: '24' }} maxW="7xl" height={{base: "auto", lg: "570px"}}>
            <HStack height="100%" spacing={{ base: '12', md: '16' }} flexFlow={{base: "column", lg: "row"}} justifyContent="space-between">
                <Stack height="inherit" width="100%" justifyContent="space-between" spacing={{ base: '4', md: '5' }} pb={{base: "24px", lg: "0px"}} >
                    <Stack spacing="3">
                            <Text fontSize="5xl" color="yellow.400" fontWeight="700" > How Can We Help?</Text>
                             <Text color="white" fontSize="18px">
                                Our Customer service is here to clear out any dilemma. <br></br> Feel free to give us a call!
                        </Text>
                    </Stack>
                   
                        
                    <VStack alignItems="flex-start">
                        <HStack><IconPhone fill="#E0B700" stroke="#E0B700" color="#E0B700"  /> <Link href="tel:18004651525" color="white" fontWeight="700">1 800 465 1525</Link></HStack>
                        <HStack><IconPhone fill="#E0B700" stroke="#E0B700" color="#E0B700" /> <Link href="tel:1-855-809-1510 " color="white" fontWeight="700">FAX 1-855-809-1511  </Link></HStack>
                        <HStack><IconMail  color="#E0B700"  /> <Link href="mailto:info@acemedicalwholesale.com" color="white" fontWeight="700">info@acemedicalwholesale.com </Link></HStack>
                    </VStack>
                </Stack>

                <Image
                    src="https://ace.forgaservers.com/wp-content/uploads/2022/09/customerservice.jpg"
                    width={{base: "100%", lg:"656px"}}
                    marginInlineStart={{ base: "0px !important", lg: 12 }}
                    borderRadius="8px"
                />
            </HStack>
        </Container>
    </Box>
    )
}

export default IndexLastSection
